import * as React from 'react';
import '../../styles/global.scss';
import '../../styles/normalize.scss';
import '../../styles/typography.scss';
import '../../styles/settings.scss';
import RootLayout from '../../components/RootLayout/RootLayout';
import Headers from '../../components/Headers/Headers';
import Footer from '../../components/Footer/Footer';
import ScroolUpButton from '../../components/ScroolUpButton/ScroolUpButton';
import BlogArticleChoosingWebsite from '../../components/BlogArticles/BlogArticleChoosingWebsite';

const BlogJakaStrona = () => {
  const title_top = 'How to Choose ';
  const title_middle = 'the Perfect';
  const title_bottom = 'Website';
  const siteTitle =
    'How to Choose the Perfect Website for Your Business: One-Page, Multi-Page, or CMS? - Nowoczesna witryna';
  const siteDescription =
    'Choosing the right type of website is crucial for the online success of any business. Different types of websites serve different business needs – from simple One-Page sites to extensive Multi-Page websites and CMS-based sites. In this article, we explore the unique features of each type and offer guidance on selecting the ideal website for your business.';
  const siteUrl = 'https://www.nowoczesnawitryna.com/blog/how-to-choose-the-perfect-website/';
  const siteUrlOtherLang = 'https://www.nowoczesnawitryna.pl/blog/jak-wybrac-idealna-strone/';

  return (
    <>
      <RootLayout title={siteTitle} url={siteUrl} urlOtherLang={siteUrlOtherLang} description={siteDescription} />
      <Headers title_top={title_top} title_middle={title_middle} title_bottom={title_bottom} active="blog" />
      <ScroolUpButton />
      <BlogArticleChoosingWebsite />
      <Footer />
    </>
  );
};

export default BlogJakaStrona;
